import React, { useEffect } from "react";
import { graphql } from "gatsby";
import Lottie from "lottie-web";
import { AnimatePresence, motion } from "framer-motion";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import PageBanner from "@components/pageModules/modules/pageBanner";
import { PageMeta } from "@components/pageMeta";

const PreLoader = ({ asset, setHideLoader }) => {
  const lottieRef = React.useRef(null);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: lottieRef.current,
      renderer: "svg",
      loop: false,
      autoplay: false,
      path: asset?.url,
    });

    anim.play();

    anim.onComplete = function () {
      setHideLoader(true);
    };

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed top-0 left-0 h-screen w-full z-[9999] flex items-center justify-center bg-white scale-150 md:scale-100"
    >
      <div ref={lottieRef} />
    </motion.div>
  );
};

const IndexPage = ({ data: { page } }) => {
  const { pageModules, config, seo, banner, pageLoader, leafBackground } =
    page || {};

  const [hideLoader, setHideLoader] = React.useState(false);

  return (
    <Layout {...config} reverseHeader={true} leafBackground={leafBackground}>
      <PageMeta {...seo} />
      <AnimatePresence exitBeforeEnter>
        {!hideLoader && (
          <PreLoader
            key={hideLoader}
            setHideLoader={setHideLoader}
            {...pageLoader}
          />
        )}
      </AnimatePresence>
      {/* <PageBanner {...banner} /> */}
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      banner {
        ...PageBanner
      }
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
      leafBackground
      pageLoader {
        asset {
          url
        }
      }
    }
  }
`;
